<template>
  <base-view
    title="Autorizar/Rechazar traspasos"
    description="Autoriza o rechaza los traspasos pendientes"
    background="bg-gradient-purple-to-black"
    icon="repeat"
    header-type="overlap"
  >
    <b-card no-body>
      <b-card-header>
        <div class="row align-items-center">
          <div class="col">
            <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
              <router-link :to="{ name: 'PeriodosFiscales' }">
                <b-button size="sm">
                  Cambiar periodo fiscal activo
                </b-button>
              </router-link>
            </check-authorization>
          </div>
          <div class="col">
            <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
          </div>
        </div>
      </b-card-header>

      <div class="row py-1 p-4">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Filtrar por</b-input-group-text>
          </template>

          <b-form-select
            v-model="selected"
            :options="options"
            @input="onChangeEstado"
          >
          </b-form-select>
        </b-input-group>
      </div>

      <b-overlay :show="Boolean(loading)">
        <template #overlay>
          <loading></loading>
        </template>
        <div class="row my-1 p-4">
          <portal-target name="notificationTraspasosSidebarAlert" />

          <b-table
            :fields="fields"
            :items="list"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell(id)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ data.item }}
              </div>
            </template>
            <template #cell(concepto)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ data.item.concepto }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
              </div>
            </template>
            <template #cell(folio)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ data.item.folio }}</b-badge>
              </div>
            </template>
            <template #cell(mes)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="secondary" class="ml-2">
                  {{ getMonthById(data.item.mes).value }}
                </b-badge>
              </div>
            </template>
            <template #cell(periodo_fiscal_id)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ data.item.periodo_fiscal_id }}</b-badge>
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-dark text-center text-sm w-100">
                {{ $formatNumber(data.item.total) }}
              </div>
            </template>
            <template #cell(ampliaciones)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ $formatNumber(data.item.ampliaciones) }}
              </div>
            </template>
            <template #cell(reducciones)="data">
              <div class="text-danger text-center text-sm w-100">
                {{ $formatNumber(data.item.reducciones) }}
              </div>
            </template>
            <template #row-details="row">
              <traspasos-movimientos-list :traspaso_id="row.item.id"></traspasos-movimientos-list>
            </template>
            <template #cell(acciones)="row">
              <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
                <b-button
                  class="mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="info"
                  @click="
                    row.toggleDetails()
                    onShowMovimientos(row.item.id)
                  "
                >
                  Movimientos
                </b-button>

                <check-authorization :requiresAuthorizations="['exportar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="primary"
                    @click="onExport(row.item.id, row.item.folio)"
                  >
                    Exportar
                  </b-button>
                </check-authorization>

                <check-authorization :requiresAuthorizations="['autorizar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="success"
                    @click="onAuthorize(row.item)"
                  >
                    Autorizar
                  </b-button>
                </check-authorization>

                <check-authorization :requiresAuthorizations="['rechazar traspasos']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="danger"
                    @click="onReject(row.item)"
                  >
                    Rechazar
                  </b-button>
                </check-authorization>
              </div>
            </template>
          </b-table>
        </div>
      </b-overlay>
    </b-card>

    <b-modal
      v-model="showModal"
      title="Realizar movimiento"
      size="sm"
      centered
      no-close-on-backdrop
    >
      {{ titleModal }}
      <div v-if="autorizar === 0">
        <!-- Controles adicionales para la autorización -->
        <b-form-group id="fieldset-1" label="Folio de Autorización" label-for="input-folio"
                      invalid-feedback="Ingrese un folio de autorización">
          <b-form-input id="input-folio" v-model="folio_autorizacion"
                        :state="folio_state" placeholder="Ingrese el folio de autorización"></b-form-input>
        </b-form-group>
        <b-form-group id="fieldset-2" label="Fecha de Autorización" label-for="input-fecha"
                      invalid-feedback="Ingrese una fecha de autorización">
          <b-form-datepicker id="input-fecha" v-model="fecha_autorizacion"
                             :state="fecha_state" class="mb-2"></b-form-datepicker>
        </b-form-group>
        <b-form-group id="fieldset-3" label="Texto" label-for="input-texto"
                      invalid-feedback="Redacte el texto de autorización">
          <b-form-textarea id="input-texto" v-model="texto_autorizacion"
                           :state="text_state" rows="3" max-rows="6"></b-form-textarea>
        </b-form-group>
        <b-form-group id="fieldset-4" label="C.C.P." label-for="input-ccp"
                      invalid-feedback="Especifique los nombres para las copias" description="Agregue cada nombre en una línea separada">
          <b-form-textarea id="input-ccp" v-model="ccp_autorizacion"
                           :state="ccp_state" rows="3" max-rows="6"></b-form-textarea>
        </b-form-group>
      </div>
      <div v-if="autorizar === 1">
        <b-form-group id="fieldset-5" label="Concepto de rechazo" label-for="input-concepto-rechazo"
                      invalid-feedback="Redacte el texto de rechazo">
          <b-form-textarea id="input-concepto-rechazo" v-model="texto_rechazo"
                           :state="rechazo_state" rows="3" max-rows="6"></b-form-textarea>
        </b-form-group>
      </div>
      <template #modal-footer="{ cancel }">
        <div class="d-flex">
          <b-button
            variant="outline-secondary"
            size="sm"
            @click="cancel()"
            class="mr-2"
          >
            Cancelar
          </b-button>

          <b-button variant="success" size="sm" @click="confirm()">Confirmar</b-button>
        </div>
      </template>
    </b-modal>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import TraspasosMovimientosList from '@/components/Traspasos/TraspasosMovimientosList'

export default {
  name: 'AutorizarTraspasosView',

  components: {
    TraspasosMovimientosList
  },

  data () {
    return {
      fields: ['folio', 'fecha', 'concepto', 'total', 'ampliaciones', 'reducciones', 'acciones'],

      loading: false,

      selectedTraspaso: null,

      traspasos: null,

      selected: 3,

      list: [],

      options: [
        { text: 'Enviados', value: 3 }
      ],

      showSidebar: false,

      showModal: false,

      modal_error: false,

      titleModal: '',

      payload: {
        folio_autorizacion: '',
        fecha_autorizacion: '',
        texto_autorizacion: '',
        texto_rechazo: '',
        ccp_autorizacion: ''
      },

      // Autorizar: 0
      // Rechazar: 1
      autorizar: 0,
      folio_autorizacion: '',
      fecha_autorizacion: '',
      texto_autorizacion: '',
      texto_rechazo: '',
      ccp_autorizacion: ''

    }
  },

  async created () {
    await this.onChangeEstado(3)
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById']),
    folio_state () {
      return !this.modal_error ? null : !(this.folio_autorizacion === '')
    },
    fecha_state () {
      return !this.modal_error ? null : !(this.fecha_autorizacion === '')
    },
    text_state () {
      return !this.modal_error ? null : !(this.texto_autorizacion === '')
    },
    ccp_state () {
      return !this.modal_error ? null : !(this.ccp_autorizacion === '')
    },
    rechazo_state () {
      return !this.modal_error ? null : !(this.texto_rechazo === '')
    }
  },

  methods: {
    async onChangeEstado (value) {
      this.loading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      const getval = await this.$store.dispatch('traspasoModule/listar', {
        periodo_fiscal_id: this.periodoActivo.id,
        autorizar: true,
        autorizado: value
      })

      if (getval.error) {
        this.$alert(getval, 'Traspasos.', 'notificationTraspasosSidebarAlert', {
          status: 'danger'
        })
      } else {
        this.list = getval.data
      }

      this.loading = false
    },

    onShowMovimientos (id) {
      this.selectedTraspaso = this.list.find(el => el.id === id)
    },

    onSelect (id) {
      this.selectedTraspaso = this.list.find(el => el.id === id)

      this.showSidebar = true
    },

    async onExport (id, folio) {
      const getval = await this.$store.dispatch('traspasoModule/exportTraspasos', id)

      await this.$onDownload(getval, `traspaso.${folio}.xlsx`)

      this.$notify({ message: `Solicitud de descarga de traspaso con folio ${folio}.` })
    },

    async reload () {
      await this.onChangeEstado(this.selected)
    },

    onAuthorize (item) {
      // Verificamos las condiciones bajo las cuáles no se puede autorizar
      if (item.no_movimientos === 0) {
        this.$notify({ error: 422, message: 'El traspaso no tiene movimientos, no se puede autorizar' })
        return
      }
      if (item.ampliaciones !== item.reducciones) {
        this.$notify({ error: 422, message: 'El traspaso tiene diferencias entre el total de ampliaciones y reducciones, no se puede autorizar' })
        return
      }
      if (item.autorizado === 0) {
        this.$notify({ error: 422, message: 'El traspaso tiene estatus pendiente, no es posible autorizar' })
        return
      }
      if (item.autorizado === 1) {
        this.$notify({ error: 422, message: 'El traspaso tiene estatus autorizado, no es posible autorizar de nuevo' })
        return
      }
      if (item.autorizado === 2) {
        this.$notify({ error: 422, message: 'El traspaso tiene estatus rechazado, no es posible autorizar' })
        return
      }
      this.titleModal = '¿Autorizar traspaso?'
      this.autorizar = 0
      this.showModal = true
      this.modal_error = false

      this.payload = {
        traspaso_id: item.id
      }
    },

    onReject (item) {
      // Verificamos las condiciones bajo las cuáles no se puede rechazar
      if (item.autorizado === 0) {
        this.$notify({ error: 422, message: 'El traspaso tiene estatus pendiente, no es posible rechazar' })
        return
      }
      if (item.autorizado === 1) {
        this.$notify({ error: 422, message: 'El traspaso tiene estatus autorizado, no es posible rechazar' })
        return
      }
      if (item.autorizado === 2) {
        this.$notify({ error: 422, message: 'El traspaso tiene estatus rechazado, no es posible rechazar de nuevo' })
        return
      }
      this.titleModal = '¿Rechazar traspaso?'
      this.autorizar = 1
      this.showModal = true
      this.modal_error = false

      this.payload = {
        traspaso_id: item.id
      }
    },

    async confirm () {
      this.showModal = false
      this.loading = true

      if (this.autorizar === 0) {
        // Autorizar
        if ((this.folio_autorizacion === '') ||
          (this.fecha_autorizacion === '') ||
          (this.texto_autorizacion === '') ||
          (this.ccp_autorizacion === '')) {
          this.$notify({ error: 422, message: 'Debe proporcionar la información' })
          this.modal_error = true
          this.showModal = true
          this.loading = false
          return
        }

        this.payload.folio = this.folio_autorizacion
        this.payload.fecha = this.fecha_autorizacion
        this.payload.text = this.texto_autorizacion
        this.payload.ccp = this.ccp_autorizacion
        const { error, message } = await this.$store.dispatch('traspasoModule/autorizarTraspaso', this.payload)
        if (error) {
          this.$notify({ error, message })
        } else {
          this.$notify({ message: message })
        }
      } else {
        // Rechazar
        if (this.texto_rechazo === '') {
          this.$notify({ error: 422, message: 'Debe proporcionar la información' })
          this.modal_error = true
          this.showModal = true
          this.loading = false
          return
        }

        this.payload.texto_rechazo = this.texto_rechazo
        const { error, message } = await this.$store.dispatch('traspasoModule/rechazarTraspaso', this.payload)
        if (error) {
          this.$notify({ error, message })
        } else {
          this.$notify({ message: message })
          this.reload()
        }
      }
      this.loading = false
    }
  }
}
</script>
